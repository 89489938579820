/* Add fonts here */
@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  src: url('src/assets/fonts/IBMPlexSans/IBMPlexSans-Regular.woff2')
      format('woff2'),
    url('src/assets/fonts/IBMPlexSans/IBMPlexSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  src: url('src/assets/fonts/IBMPlexSans/IBMPlexSans-Medium.woff2')
      format('woff2'),
    url('src/assets/fonts/IBMPlexSans/IBMPlexSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  src: url('src/assets/fonts/IBMPlexSans/IBMPlexSans-Bold.woff2')
      format('woff2'),
    url('src/assets/fonts/IBMPlexSans/IBMPlexSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Whyte Inktrap';
  font-weight: 400;
  src: url('src/assets/fonts/Inktrap/WhyteInktrap-Regular.woff2')
      format('woff2'),
    url('src/assets/fonts/Inktrap/WhyteInktrap-Regular.woff') format('woff'),
    url('src/assets/fonts/Inktrap/WhyteInktrap-Regular.ttf') format('ttf');
}
